import { render, staticRenderFns } from "./articleLive.vue?vue&type=template&id=206cc8d0&scoped=true"
import script from "./articleLive.vue?vue&type=script&lang=js"
export * from "./articleLive.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./articleLive.vue?vue&type=style&index=1&id=206cc8d0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206cc8d0",
  null
  
)

export default component.exports