<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>竞猜管理</span>
                    <span style="margin-left: 20px;">账户竞猜余额：<span style="font-weight: bold;font-size: 20px;">1,001,555</span></span>
                </div>
                <div class="left-box">
                    <div class="main-box-right-box">
                        <div class="main-box-right-item" v-for="(item,index) in gameData" :key="index">
                            <div class="main-box-time">
                            <span>{{formatTime(item.starttime)}}</span> 
                            <div class="main-box-singletime">单节: {{item.singletime}}</div>
                            </div>
                            <div class="team-logo-box">
                                <div class="team-box">
                                    <div class="team-logo">
                                        <div class="block">
                                            <el-avatar shape="circle" :size="60" :src="item.homelogo"></el-avatar>
                                        </div>
                                    </div>
                                    <div class="team-info" style="text-align: center;">
                                        <span class="team-info-name">{{item.homename}}</span>
                                    </div>
                                </div>
                                
                                <div class="team-vs">
                                    VS
                                </div>
                                
                                <div class="team-box">
                                    <div class="team-logo">
                                        <div class="block" style="margin-left: 10px;">
                                            <el-avatar shape="circle" :size="60" :src="item.guestlogo" fit="cover"></el-avatar>
                                        </div>
                                    </div>
                                    <div class="team-info" style="text-align: center;">
                                        <span class="team-info-name">{{item.guestname}}</span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="btn-area">
                                <el-button type="" size="mini">通知本场</el-button>
                                <el-button type="" size="mini" @click="editScale(item)">编辑赔率</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-box-right">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>赔率设置</span>
                </div>
                <div style="width: 100%;text-align: center;height: 60px;line-height: 60px;font-size: 26px;font-weight: bold;">
                    <span style="color:#f00;margin-right: 30px;">{{ homename }}</span>VS
                    <span style="color:#00f;margin-left: 30px;">{{ guestname }}</span></div>
                <el-timeline>
                    <el-timeline-item timestamp="比赛开始前" placement="top">
                      <el-card>
                        <el-table
                              :row-class-name="rowClassName"
                              :data="guessData[0]"
                              style="width: 100%">
                              <el-table-column
                                prop="type"
                                label="竞猜类型"
                                width="300">
                              </el-table-column>
                              <el-table-column
                                prop="odds"
                                label="赔率"
                                width="">
                              </el-table-column>
                              <el-table-column
                                prop="tools"
                                width="200"
                                align="center"
                                label="操作">
                                <template slot-scope="scope">
                                    <span style="color: #00f;cursor:pointer" @click="editodds(scope.row)">修改</span>&emsp;
                                    
                                    <span style="color: #f00;cursor:pointer" v-if="scope.row.state"  @click="scope.row.state = false">关闭</span>
                                    <span style="color: #3aa607;cursor:pointer" v-else  @click="scope.row.state = true">开启</span>
                                </template>
                              </el-table-column>
                            </el-table>
                      </el-card>
                    </el-timeline-item>
                    <el-timeline-item timestamp="第一节开始前" placement="top">
                      <el-card>
                        <el-table
                                :row-class-name="rowClassName"
                                :data="guessData[1]"
                                style="width: 100%">
                                <el-table-column
                                  prop="type"
                                  label="竞猜类型"
                                  width="300">
                                </el-table-column>
                                <el-table-column
                                  prop="odds"
                                  label="赔率"
                                  width="">
                                </el-table-column>
                                <el-table-column
                                  prop="tools"
                                  width="200"
                                  align="center"
                                  label="操作">
                                  <template slot-scope="scope">
                                      <span style="color: #00f;cursor:pointer" @click="editodds(scope.row)">修改</span>&emsp;
                                      
                                      <span style="color: #f00;cursor:pointer" v-if="scope.row.state"  @click="scope.row.state = false">关闭</span>
                                      <span style="color: #3aa607;cursor:pointer" v-else  @click="scope.row.state = true">开启</span>
                                  </template>
                                </el-table-column>
                              </el-table>
                       
                      </el-card>
                    </el-timeline-item>
                    <el-timeline-item timestamp="第二节开始前" placement="top">
                      <el-card>
                        <el-table
                                :row-class-name="rowClassName"
                                :data="guessData[2]"
                                style="width: 100%">
                                <el-table-column
                                  prop="type"
                                  label="竞猜类型"
                                  width="300">
                                </el-table-column>
                                <el-table-column
                                  prop="odds"
                                  label="赔率"
                                  width="">
                                </el-table-column>
                                <el-table-column
                                  prop="tools"
                                  width="200"
                                  align="center"
                                  label="操作">
                                  <template slot-scope="scope">
                                      <span style="color: #00f;cursor:pointer" @click="editodds(scope.row)">修改</span>&emsp;
                                      
                                      <span style="color: #f00;cursor:pointer" v-if="scope.row.state"  @click="scope.row.state = false">关闭</span>
                                      <span style="color: #3aa607;cursor:pointer" v-else  @click="scope.row.state = true">开启</span>
                                  </template>
                                </el-table-column>
                              </el-table>
                    
                      </el-card>
                    </el-timeline-item>
                    <el-timeline-item timestamp="第三节开始前" placement="top">
                      <el-card>
                        <el-table
                                :row-class-name="rowClassName"
                                :data="guessData[3]"
                                style="width: 100%">
                                <el-table-column
                                  prop="type"
                                  label="竞猜类型"
                                  width="300">
                                </el-table-column>
                                <el-table-column
                                  prop="odds"
                                  label="赔率"
                                  width="">
                                </el-table-column>
                                <el-table-column
                                  prop="tools"
                                  width="200"
                                  align="center"
                                  label="操作">
                                  <template slot-scope="scope">
                                      <span style="color: #00f;cursor:pointer" @click="editodds(scope.row)">修改</span>&emsp;
                                      
                                      <span style="color: #f00;cursor:pointer" v-if="scope.row.state"  @click="scope.row.state = false">关闭</span>
                                      <span style="color: #3aa607;cursor:pointer" v-else  @click="scope.row.state = true">开启</span>
                                  </template>
                                </el-table-column>
                              </el-table>
                     
                      </el-card>
                    </el-timeline-item>
                    <el-timeline-item timestamp="第四节开始前" placement="top">
                      <el-card>
                        <el-table
                                :row-class-name="rowClassName"
                                :data="guessData[4]"
                                style="width: 100%">
                                <el-table-column
                                  prop="type"
                                  label="竞猜类型"
                                  width="300">
                                </el-table-column>
                                <el-table-column
                                  prop="odds"
                                  label="赔率"
                                  width="">
                                </el-table-column>
                                <el-table-column
                                  prop="tools"
                                  width="200"
                                  align="center"
                                  label="操作">
                                  <template slot-scope="scope">
                                      <span style="color: #00f;cursor:pointer" @click="editodds(scope.row)">修改</span>&emsp;
                                      
                                      <span style="color: #f00;cursor:pointer" v-if="scope.row.state"  @click="scope.row.state = false">关闭</span>
                                      <span style="color: #3aa607;cursor:pointer" v-else  @click="scope.row.state = true">开启</span>
                                  </template>
                                </el-table-column>
                              </el-table>
    
                      </el-card>
                    </el-timeline-item>
                  </el-timeline>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                List: [],
                gameData: [
                    {
                        id : 1,
                        homename: "40+主队名称超长名称测试",
                        guestname: "40+客队名称超长名称测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: "1713687113",
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 2,
                        homename: "超短名称",
                        guestname: "超短名称",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: "1713687113",
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 3,
                        homename: "中等名称 测试",
                        guestname: "中等名称 测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: "1713687113",
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 4,
                        homename: "中等名称 测试",
                        guestname: "中等名称 测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: "1713687113",
                        singletime: "10分钟"
                        
                    }
                ],
                homename: "",
                guestname: "",
                guessData: [
                    [
                        {id: 1, type: "主队先进球",team: 1,odds: 1.5, state: true},
                        {id: 2, type: "客队先进球",team: 2,odds: 1.5, state: true},
                        {id: 3, type: "主队先进球一分",team: 1,odds: 1.5, state: true},
                        {id: 4, type: "客队先进球一分",team: 2,odds: 1.5, state: true},
                        {id: 5, type: "主队先进球二分",team: 1,odds: 1.5, state: true},
                        {id: 6, type: "客队先进球二分",team: 2,odds: 1.5, state: true},
                        {id: 7, type: "主队先进球三分",team: 1,odds: 1.5, state: true},
                        {id: 8, type: "客队先进球三分",team: 2,odds: 1.5, state: true},
                        {id: 9, type: "主队先犯规",team: 1,odds: 1.5, state: true},
                        {id: 10, type: "客队先犯规",team: 2,odds: 1.5, state: true},
                        {id: 11, type: "第一节两队总得分单数",team: 3,odds: 1.5, state: true},
                        {id: 12, type: "第一节两队总得分双数",team: 3,odds: 1.5, state: true},
                        {id: 13, type: "第一节两队总犯规单数",team: 3,odds: 1.5, state: true},
                        {id: 14, type: "第一节两队总犯规双数",team: 3,odds: 1.5, state: true},
                        {id: 15, type: "本场比赛得一分最高球员",team: 3,odds: 1.5, state: true},
                        {id: 16, type: "本场比赛得二分最高球员",team: 3,odds: 1.5, state: true},
                        {id: 17, type: "本场比赛犯规最多球员",team: 3,odds: 1.5, state: true},
                        {id: 18, type: "本场比赛总得分为单数",team: 3,odds: 1.5, state: true},
                        {id: 19, type: "本场比赛总得分为双数",team: 3,odds: 1.5, state: true},
                        {id: 20, type: "本场比赛主队胜",team: 1,odds: 1.5, state: true},
                        {id: 21, type: "本场比赛客队胜",team: 2,odds: 1.5, state: true},
                    ],
                    [
                        {id: 1, type: "主队先进球",team: 1,odds: 1.5, state: true},
                        {id: 2, type: "客队先进球",team: 2,odds: 1.5, state: true},
                        {id: 3, type: "主队先进球一分",team: 1,odds: 1.5, state: true},
                        {id: 4, type: "客队先进球一分",team: 2,odds: 1.5, state: true},
                        {id: 5, type: "主队先进球二分",team: 1,odds: 1.5, state: true},
                        {id: 6, type: "客队先进球二分",team: 2,odds: 1.5, state: true},
                        {id: 7, type: "主队先进球三分",team: 1,odds: 1.5, state: true},
                        {id: 8, type: "客队先进球三分",team: 2,odds: 1.5, state: true},
                        {id: 9, type: "主队先犯规",team: 1,odds: 1.5, state: true},
                        {id: 10, type: "客队先犯规",team: 2,odds: 1.5, state: true},
                        {id: 11, type: "第二节两队总得分单数",team: 3,odds: 1.5, state: true},
                        {id: 12, type: "第二节两队总得分双数",team: 3,odds: 1.5, state: true},
                        {id: 13, type: "第二节两队总犯规单数",team: 3,odds: 1.5, state: true},
                        {id: 14, type: "第二节两队总犯规双数",team: 3,odds: 1.5, state: true},
                    ],
                    [
                        {id: 1, type: "主队先进球",team: 1,odds: 1.5, state: true},
                        {id: 2, type: "客队先进球",team: 2,odds: 1.5, state: true},
                        {id: 3, type: "主队先进球一分",team: 1,odds: 1.5, state: true},
                        {id: 4, type: "客队先进球一分",team: 2,odds: 1.5, state: true},
                        {id: 5, type: "主队先进球二分",team: 1,odds: 1.5, state: true},
                        {id: 6, type: "客队先进球二分",team: 2,odds: 1.5, state: true},
                        {id: 7, type: "主队先进球三分",team: 1,odds: 1.5, state: true},
                        {id: 8, type: "客队先进球三分",team: 2,odds: 1.5, state: true},
                        {id: 9, type: "主队先犯规",team: 1,odds: 1.5, state: true},
                        {id: 10, type: "客队先犯规",team: 2,odds: 1.5, state: true},
                        {id: 11, type: "第三节两队总得分单数",team: 3,odds: 1.5, state: true},
                        {id: 12, type: "第三节两队总得分双数",team: 3,odds: 1.5, state: true},
                        {id: 13, type: "第三节两队总犯规单数",team: 3,odds: 1.5, state: true},
                        {id: 14, type: "第三节两队总犯规双数",team: 3,odds: 1.5, state: true},
                        ],
                    [
                        {id: 1, type: "主队先进球",team: 1,odds: 1.5, state: true},
                        {id: 2, type: "客队先进球",team: 2,odds: 1.5, state: true},
                        {id: 3, type: "主队先进球一分",team: 1,odds: 1.5, state: true},
                        {id: 4, type: "客队先进球一分",team: 2,odds: 1.5, state: true},
                        {id: 5, type: "主队先进球二分",team: 1,odds: 1.5, state: true},
                        {id: 6, type: "客队先进球二分",team: 2,odds: 1.5, state: true},
                        {id: 7, type: "主队先进球三分",team: 1,odds: 1.5, state: true},
                        {id: 8, type: "客队先进球三分",team: 2,odds: 1.5, state: true},
                        {id: 9, type: "主队先犯规",team: 1,odds: 1.5, state: true},
                        {id: 10, type: "客队先犯规",team: 2,odds: 1.5, state: true},
                        {id: 11, type: "第四节两队总得分单数",team: 3,odds: 1.5, state: true},
                        {id: 12, type: "第四节两队总得分双数",team: 3,odds: 1.5, state: true},
                        {id: 13, type: "第四节两队总犯规单数",team: 3,odds: 1.5, state: true},
                        {id: 14, type: "第四节两队总犯规双数",team: 3,odds: 1.5, state: true},
                    ]
                    
                ]
            }
        },
        created() {
            this.$api.post('api/Main/GetGame').then(response => {
                if (response.data.code == 0) {
                    this.$message({
                      type: 'error',
                      message: response.data.data
                    });
                    this.$router.push({ path: '/login'});
                    return;
                }
            }).catch(error => {
                console.error("There was an error!", error);
            }) 
        },
        methods: {
            formatDate(value) {
                const date = new Date(value);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit' };
                return date.toLocaleDateString('default', options);
            },
            formatTime(value) {
                const date = new Date(value);
                const options = {  month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit' };
                return date.toLocaleString('default',options);
            },
            editScale(item) {
                console.log(item)
                this.homename = item.homename
                this.guestname = item.guestname
                this.$forceUpdate();
                console.log(this.homename)
            },
            rowClassName({ row }) {
                if (row.team === 1 && row.state) {
                  return 'team-one-background';
                } else if (row.team === 2 && row.state) {
                  return 'team-two-background';
                } else if (row.team === 3 && row.state) {
                    return 'team-three-background';
                } else if (!row.state) {
                    return 'team-fail-background';
                }
                else {
                  return '';
                }
            },
            editodds(row) {
                this.$prompt('修改赔率', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: row.odds
                }).then(({ value }) => {
                    let item = this.guessData[0].find(item=>item.id === row.id)
                    item.odds = value
                  this.$message({
                    type: 'success',
                    message: '修改成功 '
                  });
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消输入'
                  });       
                });
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-info {
        display: flex;
        flex-direction: column;
        
    }
    
    .box-card {
        width: 100%;
        height: 100px;
        position: relative;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    
    .main-box-info i {
        font-size: 60px;
        color: #a5a5a5;
        
    }
    
    .main-box-titel {
        font-size: 26px;
        font-weight: bold;
        margin-top: 20px;
        
    }
    
    .main-box-detail {
        color: #666;
        font-size: 16px;
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    
    .guess-btn {
        width:100px;
        position: absolute;
        right: 20px;
        bottom: 40px;
        margin: auto;
    }
    
    
    
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 - 50px);
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 - 50px);
        margin: 10px;
        text-align: left;
    }
    
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .left-box {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .main-box-right-box {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    .main-box-right-item {
        width: calc(100% / 2 - 40px);
        height: 200px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 10px;
        position: relative;
    }
    
    .main-box-time {
        color: #aaaaaa;
        margin-bottom: 10px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;
    }
    
    .main-box-singletime {
        color: #aaaaaa;
        font-size: 12px;
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 0px;
        position: relative;
    }
    
    .team-box {
        display: flex;
        width: 200px;
        flex-direction: column;
    }
    
    .team-vs {
        width: 50px;
        font-weight: bold;
        font-size: 20px;
        line-height: 60px;
        padding: 0px 10px;
    }
    
    .font-blue {
        color: #009dff;
        font-size: 34px
    }
    
    .font-red {
        color: #ff0004;
        font-size: 34px
    }
    
    
    .team-info-name {
        font-size: 14px;
        font-weight: bold;
    
    }
    
    .team-info-score {
        text-align: left;
        font-size: 13px;
    }
    
    .btn-area {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 10px;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
    }
    
    .main-box-nav {
        height: 80px;
        background-color: #fff;
        width: calc(100% - 20px);
        margin: 0 auto;
        border-radius: 15px;
        line-height: 80px;
        text-align: left;
    }
    
    ::v-deep .team-one-background {
      background-color: #fbe5d6; /* 主队背景色 */
    }
    
    ::v-deep .team-two-background {
      background-color: #dae3f3; /* 客队背景色 */
    }
    
    ::v-deep .team-three-background {
      background-color: #e2f0d9; /* 客队背景色 */
    }
    
    ::v-deep .team-fail-background {
        background-color: #ccc;
    }
</style>