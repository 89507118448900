<template>
	<div class="content">
		<top-nav title="视频管理"></top-nav>
		<el-input
		    class="search-box"
		    placeholder="搜索视频"
		    prefix-icon="el-icon-search"
			@keydown.enter.native="searchVideo()"
		    v-model="searchKey">
		</el-input>
		<div class="tool-box">
			<el-button type="primary" @click="allCheck">全选</el-button>
			<el-button @click="sortVideoList">按比赛排序</el-button>
			<el-button @click="sortVideoListbyTime">按时间排序</el-button>
			<el-button type="danger" v-if="chooseList.length != 0" @click="deleteChoose">删除选中</el-button>
		</div>
		<el-checkbox-group v-model="chooseList">
			
			<el-skeleton :loading="loading" animated :count="1">
				
				<template slot="template">
					<div style="
						display: grid;
						grid-template-columns: repeat(6, 1fr);
						gap: 16px;
						grid-template-rows: repeat(auto-fill, 300px);
					">
						<div class="skeleton-item" style="width: 250px" v-for="(item,index) in videoList" :key="index">
							<el-skeleton-item
								variant="image"
								style="width: 250px;height: 200px;"
							/>
							<div style="padding: 14px;">
								<el-skeleton-item variant="h3" style="width: 50%;" />
								<div
									style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
								>
									<el-skeleton-item variant="text" style="margin-right: 16px;" />
									<el-skeleton-item variant="text" style="width: 30%;" />
								</div>
							</div>
						</div>
					</div>
				</template>
			</el-skeleton>
			<div class="main-box" v-show="!loading">
					<div class="video-item" v-for="(item,index) in videoList" :key="index" :class="{isChoose : chooseList.includes(item.id)}">
						<el-checkbox class="video-checkBox" :label="item.id"></el-checkbox>
						<div v-if="item.poster" class="posterImage" @click="playVideo(item.id)">
						  <el-image
								class="poster-image"
								:src="item.poster"
								fit="scale-down"></el-image>
							<i class="iconfont icon-bofang"></i>
						</div>
						<span class="matchTitle">{{item.match}}</span>
						<span class="matchTime">上传时间：{{item.time}}</span>
						<video v-if="item.video && !item.poster" :ref="'video'+index" @loadeddata="generatePoster(index)" class="tmp-video">
						  <source :src="item.video" type="video/mp4" />
						</video>
					</div>
			</div>
			<div class="videoMask" v-if="playUrl">
				<video :src="playUrl" controls autoplay></video>
				<div class="closeBtn" @click="playUrl = ''">
					<i class="el-icon-close"></i>
				</div>
			</div>
		</el-checkbox-group>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				posterImage: '',
				videoSrc: require('@/assets/images/video/1.mp4'),
				videoList: [
					{
						id: 1,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2012-08-01 16:41:00",
						video: require('@/assets/images/video/1.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 2,
						match: "年十八中杯赛",
						time: "2017-08-01 16:41:00",
						video: require('@/assets/images/video/2.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 3,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2021-08-01 16:41:00",
						video: require('@/assets/images/video/3.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 4,
						match: "年十八中杯赛",
						time: "2027-08-01 16:41:00",
						video: require('@/assets/images/video/4.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 5,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2020-08-01 16:41:00",
						video: require('@/assets/images/video/5.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 6,
						match: "十八中杯赛",
						time: "2010-08-01 16:41:00",
						video: require('@/assets/images/video/6.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 7,
						match: "年安阳市“圆习酒杯”第八届夏季职工篮球联赛",
						time: "2018-08-01 16:41:00",
						video: require('@/assets/images/video/7.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 8,
						match: "十八中杯赛",
						time: "2017-08-01 16:41:00",
						video: require('@/assets/images/video/1.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 9,
						match: "十八中杯赛",
						time: "2016-08-01 16:41:00",
						video: require('@/assets/images/video/1.mp4'),
						poster: "",
						isChoose: false
					},
					{
						id: 10,
						match: "十八中杯赛",
						time: "2015-08-01 16:41:00",
						video: require('@/assets/images/video/1.mp4'),
						poster: "",
						isChoose: false
					}
				],
				chooseList: [],
				loading: true,
				loadCount: 0,
				playUrl: '',
				searchKey: ''
			}
		},
		created() {
		},
		methods: {
			generatePoster(index) {
			    const video = this.$refs['video' + index][0];

			    const canvas = document.createElement('canvas');
			    const context = canvas.getContext('2d');
				const item = this.videoList[index]

			    canvas.width = video.videoWidth;
			    canvas.height = video.videoHeight;

			    video.currentTime = 1;
			    video.addEventListener('seeked', () => {
			        context.drawImage(video, 0, 0, canvas.width, canvas.height);
			        item.poster = canvas.toDataURL('image/png');
					this.loadCount++
					console.log("加载了",this.loadCount)
					console.log("一共",this.videoList.length)
					if(this.loadCount == this.videoList.length) {
						this.loading = false
					}
			    }, { once: true });
				
			},
			allCheck() {
				if (this.chooseList.length == this.videoList.length) {
					this.chooseList = []
				} else {
					this.chooseList = []
					this.chooseList = this.videoList.map(video => video.id);
				}
				
				console.log(this.chooseList)
			},
			playVideo(id) {
				const item = this.videoList.find(item => item.id === id)
				this.playUrl = item.video
				console.log(this.playUrl)
			},
			deleteChoose() {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					this.videoList = this.videoList.filter(item => !this.chooseList.includes(item.id));
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.chooseList = []
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
				
			},
			sortVideoList() {
			    this.videoList.sort((a, b) => {
					return a.match.localeCompare(b.match);
			    });
			},
			sortVideoListbyTime() {
			    this.videoList.sort((a, b) => {
					return b.time.localeCompare(a.time);
			    });
			},
			searchVideo() {
				
			}
		},
		mounted() {
		    
		}
		
	}
</script>

<style scoped>
	.search-box {
	    position: absolute;
	    top: 20px;
	    right: 60px;
		width: 250px;
		height: 40px;
	}
	
	::v-deep .el-input__inner {
	  background: #f0f0f0;
	  border-radius: 40px;
	  border: 0px;
	}
	
	.content {
	    position: relative;
	}
	
	.main-box {
		/* display: flex; */
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 16px;
		grid-template-rows: repeat(auto-fill, 300px);
	}
	
	.video-item {
		position: relative;
		width: calc(100% - 30px);
		height: 270px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		cursor: pointer;
	}
	
	.video-item:hover {
		background-color: #e7f0fa;
	}
	
	.video-item:hover .video-checkBox {
		display: block;
	}
	
	.isChoose .video-checkBox {
		display: block;
	}
	
	.isChoose {
		background-color: #e7f0fa;
	}
	
	.posterImage {
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		position: relative;
	}
	
	.posterImage i {
		position: absolute;
		margin: auto;
		top: 45%;
		bottom: 0;
		left: 0;
		right: 0;
		font-size: 30px;
		color: #ccc;
	}
	
	.poster-image {
		border-radius: 10px;
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
	}
	
	.tmp-video {
		width: calc(100% - 30px);
		aspect-ratio: 1/1;
		
	}
	
	.tool-box {
		text-align: left;
		height: 80px;
		line-height: 80px;
	}
	
	.matchTitle {
		height: 40px;
		line-height: 20px;
		font-size: 14px;
		display: -webkit-box;          /* 使元素成为弹性盒子模型 */
		-webkit-box-orient: vertical; /* 垂直排列盒子子元素 */
		-webkit-line-clamp: 2;        /* 限制显示的行数为2 */
		overflow: hidden;              /* 隐藏超出的内容 */
		text-overflow: ellipsis;
	}
	
	.matchTime {
		font-size: 12px;
		color: #ccc;
		margin-top: 15px;
	}
	
	.video-checkBox {
		position: absolute;
		left: 10px;
		top: 10px;
		display: none;
	}
	
	/deep/ .el-checkbox__inner {
		width: 20px;
		height: 20px;
	}
	
	/deep/ .el-checkbox__inner::after {
		left: 6px;
		height: 11px;
		width: 5px;
	}
	
	/deep/ .el-checkbox__label {
		display: none;
	}
	
	.videoMask {
		position: fixed;
		background-color: rgba(0, 0, 0, .6);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 9999;
		display: flex;
		justify-content: center;
		line-height: center;
	}
	
	.videoMask video {
		width: 800px;
		height: 800px;
		margin: auto;
	}
	
	.closeBtn {
		position: absolute;
		right: calc(50% - 500px);
		top: 60px;
		color: #fff;
		font-size: 60px;
		width: 60px;
		height: 60px;
		border: 2px dashed #fff;
		cursor: pointer;
	}
</style>