<template>
    <div class="content">
       <!-- <div class="topbox" style="background-color: #fff;">
            <top-nav class="topnav" title="赛程表"></top-nav>
            
        </div> -->
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>创建比赛--也可以通过手机或平板创建比赛</span>
                </div>
                <div class="main-box" style="margin-bottom: 50px;">
                    <div class="select-team">
                        <div class="select-team-item" @click="chooseTeam('home',1)">
                            <div class="add-team home-team" v-if="homeId == 0 || chooseMode == 2">
                                <i class="el-icon-plus"></i>
                                选择主队
                            </div>
                            <div class="team-select-info" v-else>
                                <el-avatar shape="circle" :size="80" :src="homeData.logo || defaultlogo"></el-avatar>
                                <span class="team-title">{{homeData.name}}</span>
                            </div>
                        </div>
                        <span class="vs">VS</span>
                        <div class="select-team-item" @click="chooseTeam('guest',1)">
                            <div class="add-team guest-team" v-if="guestId == 0 || chooseMode == 2">
                                <i class="el-icon-plus"></i>
                                选择客队
                            </div>
                            <div class="team-select-info" v-else>
                                <el-avatar shape="circle" :size="80" :src="guestData.logo || defaultlogo"></el-avatar>
                                <span class="team-title">{{guestData.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="select-start-time">
                    <el-date-picker
                          v-model="startTime"
                          type="datetime"
                          placeholder="请选择比赛开始时间">
                    </el-date-picker>
                </div>
                
                <el-dropdown trigger="click" placement="top">
                      <div class="select-start-time" :style="singleTime !== '请选择单节时长' ? 'color:#000' : ''">
                          <i class="el-icon-timer"></i>
                          {{singleTime}}
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="singleTime = '10分钟'">10分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '20分钟'">20分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '15分钟'">15分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '12分钟'">12分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '8分钟'">8分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '5分钟'">5分钟</el-dropdown-item>
                        <el-dropdown-item @click.native="singleTime = '6分钟'">6分钟</el-dropdown-item>
                      </el-dropdown-menu>
                </el-dropdown>
                <el-button type="primary" class="newGame" @click="createGame">创建比赛</el-button>
                
                
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>全部比赛</span>
                </div>
                <div v-show="gameData.length == 0">暂无比赛，添加一场比赛吧</div>
                <div class="main-box-right-box">
                    <div class="main-box-right-item" v-for="(item,index) in gameData" :key="index">
                        <div class="main-box-time">
                        <span>{{formatTime(item.starttime)}}</span> 
                        <el-dropdown trigger="click" placement="bottom">
                              <span class="el-dropdown-link">
                                <i class="el-icon-more el-icon--center" style="font-size: 20px;cursor:pointer"></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="el-icon-edit" @click.native="editGamepre(item.id)">编辑</el-dropdown-item>
                                <el-dropdown-item icon="el-icon-delete" style="color: #f00;" @click.native="deleteGame(item.id)">删除</el-dropdown-item>
                              </el-dropdown-menu>
                        </el-dropdown>
                        <div class="main-box-singletime">单节: {{item.singletime}}</div>
                        </div>
                        <div class="team-logo-box">
                            <div class="team-box">
                                <div class="team-logo">
                                    <div class="block">
                                        <el-avatar shape="circle" :size="60" :src="item.homelogo"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info" style="text-align: center;">
                                    <router-link :to="`/teammembers/${item.homename}`">
                                    <span class="team-info-name">{{item.homename}}</span>
                                    </router-link>
                                </div>
                            </div>
                            
                            <div class="team-vs">
                                VS
                            </div>
                            
                            <div class="team-box">
                                <div class="team-logo">
                                    <div class="block" style="margin-left: 10px;">
                                        <el-avatar shape="circle" :size="60" :src="item.guestlogo" fit="cover"></el-avatar>
                                    </div>
                                </div>
                                <div class="team-info" style="text-align: center;">
                                    <router-link :to="`/teammembers/${item.guestname}`">
                                        <span class="team-info-name">{{item.guestname}}</span>
                                    </router-link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
          :title="chooseTitle"
          :visible.sync="dialogVisible"
          width="60%"
          >
          <div class="select-main-box">
              <div class="select-team-nav">
                  <el-input
                      placeholder="搜索队伍"
                      prefix-icon="el-icon-search"
                      v-model="searchTeam"
                      @keydown.enter="searchteam()"
                    >
                    </el-input>
              </div>
              <div class="select-team-box">
                  <div class="team-item" 
                        v-for="(item,index) in filteredTeams" 
                        :key="index" 
                        :class="{'choose-team' : item.id == homeId || item.id == guestId}"
                        @click="selectTeam(item.id)"
                    >
                      <div class="team-logo-box">
                        <div class="type-box" 
                            :class="item.id == homeId ? 'home-check' : item.id == guestId ? 'guest-check' : ''" 
                            v-show="item.id == homeId || item.id == guestId"
                        >
                            {{homeId == item.id ? '主队已选' : guestId == item.id ? '客队已选' : ''}}
                        </div>
                          <div class="team-logo">
                              <div class="block">
                                  <el-avatar shape="square" :size="60" :src="item.logo || defaultlogo"></el-avatar>
                              </div>
                          </div>
                          <div class="team-info">
                              <span class="team-info-name">{{item.name}}</span>
                              <span class="team-info-score">{{item.shortnick}}</span>
                          </div>
                          <div class="group-box">{{item.teamgroup}}</div>
                      </div>
                  </div>
                  
              </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelChoose()">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="编辑比赛"
          :visible.sync="dialogGameVisible"
          width="40%"
          >
          <div class="main-box" style="margin-bottom: 50px;">
              <div class="select-team">
                  <div class="select-team-item" @click="chooseTeam('home',2)">
                      <div class="team-select-info">
                          <el-avatar shape="circle" :size="80" :src="editData.homeData.logo || defaultlogo"></el-avatar>
                          <span class="team-title">{{editData.homeData.name}}</span>
                      </div>
                  </div>
                  <span class="vs">VS</span>
                  <div class="select-team-item" @click="chooseTeam('guest',2)">
                      <div class="team-select-info">
                          <el-avatar shape="circle" :size="80" :src="editData.guestData.logo || defaultlogo"></el-avatar>
                          <span class="team-title">{{editData.guestData.name}}</span>
                      </div>
                  </div>
              </div>
          </div>
          <div style="display: flex;flex-direction: column;align-items: center;">
          <div class="select-start-time">
              <el-date-picker
                    v-model="editData.starttime"
                    type="datetime"
                    placeholder="请选择比赛开始时间">
              </el-date-picker>
          </div>
          
          <el-dropdown trigger="click" placement="top">
                <div class="select-start-time" :style="editData.singletime !== '请选择单节时长' ? 'color:#000' : ''">
                    <i class="el-icon-timer"></i>
                    {{editData.singletime}}
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="editData.singletime = '10分钟'">10分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '20分钟'">20分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '15分钟'">15分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '12分钟'">12分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '8分钟'">8分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '5分钟'">5分钟</el-dropdown-item>
                  <el-dropdown-item @click.native="editData.singletime = '6分钟'">6分钟</el-dropdown-item>
                </el-dropdown-menu>
          </el-dropdown>
          
          <el-button type="primary" class="newGame" @click="editGame">确认更新</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                liveid: "",
                gamename: "",
                startTime: "",
                singleTime: "请选择单节时长",
                dialogVisible: false,
                dialogGameVisible: false,
                tableData: [],
                defaultlogo: require('@/assets/images/defaultlogo.jpg'),
                homeId: 0,
                oldHomeId: 0,
                homeData: {},
                hometmpData: {},
                guestData: {},
                guesttmpData: {},
                guestId: 0,
                oldguestId: 0,
                chooseMode: 1,
                editData: {
                    id: 0,
                    homeData: {},
                    hometmpData: {},
                    guestData: {},
                    guesttmpData: {},
                    starttime: "",
                    singletime: "",
                },
                chooseTitle: "",
                chooseType: '',
                searchTeam: '',
                editForm: {
                    gameid: 0,
                    homename: "",
                    homelogo: "",
                    guestname: "",
                    guestlogo: "",
                    starttime: "",
                    singletime: "",
                },
                gameData: [
                    {
                        id : 1,
                        homename: "40+主队名称超长名称测试",
                        guestname: "40+客队名称超长名称测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: 1713687113,
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 2,
                        homename: "超短名称",
                        guestname: "超短名称",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: 1713687113,
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 3,
                        homename: "中等名称 测试",
                        guestname: "中等名称 测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: 1713687113,
                        singletime: "10分钟"
                        
                    },
                    {
                        id : 4,
                        homename: "中等名称 测试",
                        guestname: "中等名称 测试",
                        homelogo: require('@/assets/images/defaultlogo.jpg'),
                        guestlogo: require('@/assets/images/defaultlogo.jpg'),
                        starttime: 1713687113,
                        singletime: "10分钟"
                        
                    }
                ]
            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            filteredTeams() {
              const searchTerm = this.searchTeam.trim().toLowerCase();
              if (!searchTerm) {
                return this.tableData;
              }
              return this.tableData.filter(team => 
                team.name.toLowerCase().includes(searchTerm)
              );
            }
        },
        methods: {
            fetchData() {
                let params = {
                    PageIndex: 1,
                    limit: -1
                }
                this.$api.get('api/Team/Teams', {
                  params: params
                })
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.tableData = response.data.rows
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
            },
            chooseTeam(type,mode) {
                this.dialogVisible = true
                this.chooseMode = mode
                if (mode == 1) {
                    if (type === 'home') {
                        this.chooseTitle = "选择主队"
                        this.chooseType = type
                        this.oldHomeId = this.homeId
                        this.hometmpData = this.homeData
                    }else {
                        this.chooseTitle = "选择客队"
                        this.chooseType = type
                        this.oldguestId = this.guestId
                        this.guesttmpData = this.guestData
                    }
                } else {
                    if (type === 'home') {
                        this.chooseTitle = "选择主队"
                        this.chooseType = type
                        this.editData.oldHomeId = this.homeId
                        this.editData.hometmpData = this.homeData
                    }else {
                        this.chooseTitle = "选择客队"
                        this.chooseType = type
                        this.editData.oldguestId = this.guestId
                        this.editData.guesttmpData = this.guestData
                    }
                }
                console.log(this.editData)
                
                
                
            },
            selectTeam(id) {
                if (this.chooseMode == 1) {
                    if (this.chooseType == "home") {
                        if (this.guestId == id) {
                            this.$message({
                                type: 'error',
                                message: '已经是客队了！'
                            }); 
                        }else {
                            this.homeId = id
                            this.homeData = this.tableData.find(item => item.id === id)
                        }
                    } else {
                        if (this.homeId == id) {
                            this.$message({
                                type: 'error',
                                message: '已经是主队了！'
                            }); 
                        }else {
                            this.guestId = id
                            this.guestData = this.tableData.find(item => item.id === id)
                        }
                        
                    }
                } else {
                    if (this.chooseType == "home") {
                        if (this.guestId == id) {
                            this.$message({
                                type: 'error',
                                message: '已经是客队了！'
                            }); 
                        }else {
                            this.homeId = id
                            this.editData.homeData = this.tableData.find(item => item.id === id)
                        }
                    } else {
                        if (this.homeId == id) {
                            this.editData.$message({
                                type: 'error',
                                message: '已经是主队了！'
                            }); 
                        }else {
                            this.guestId = id
                            this.editData.guestData = this.tableData.find(item => item.id === id)
                        }
                        
                    }
                }
                
            },
            cancelChoose() {
                this.homeId = this.oldHomeId
                this.guestId = this.oldguestId
                this.homeData = this.hometmpData
                this.guestData = this.guesttmpData
                this.dialogVisible = false
                this.editData = {
                    id: 0,
                    homeData: {},
                    hometmpData: {},
                    guestData: {},
                    guesttmpData: {},
                    starttime: "",
                    singletime: "",
                }
                
            },
            editGame() {
                let item = this.gameData.find(item => item.id === this.editData.id)
                item.homename = this.editData.homeData.name
                item.guestname = this.editData.guestData.name
                item.homelogo = this.editData.homeData.logo
                item.guestlogo = this.editData.guestData.logo
                item.starttime = this.editData.starttime
                item.singletime = this.editData.singletime
                this.dialogGameVisible = false
                this.editData = {
                    id: 0,
                    homeData: {},
                    hometmpData: {},
                    guestData: {},
                    guesttmpData: {},
                    starttime: "",
                    singletime: "",
                }
            },
            searchteam() {
                this.tableData = this.tableData.find()
            },
            formatDate(row, column, value) {
                const date = new Date(value);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                return date.toLocaleDateString('default', options);
            },
            formatTime(value) {
                const date = new Date(value);
                const options = {  month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit' };
                return date.toLocaleString('default',options);
            },
            createGame() {
                let gameData = {
                    id: +new Date(),
                    homename: this.homeData.name,
                    guestname: this.guestData.name,
                    homelogo: this.homeData.logo,
                    guestlogo: this.guestData.logo,
                    starttime: +new Date(),
                    singletime: this.singleTime
                }
                this.gameData.push(gameData)
            },
            editGamepre(id) {
                this.dialogGameVisible = true
                let item = this.gameData.find(item => item.id === id)
                this.editData.homeData.logo = item.homelogo
                this.editData.homeData.name = item.homename
                this.editData.guestData.logo = item.guestlogo
                this.editData.guestData.name = item.guestname
                this.editData.starttime = item.starttime
                this.editData.singletime = item.singletime
                this.editData.id = id
            },
            deleteGame(id) {
                this.gameData = this.gameData.filter(item => item.id !== id)
            }
        }
    }
</script>

<style scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 - 200px);
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(100% / 2 + 90px);
        margin: 10px;
    }
    
    #tags-container {
      display: flex;
      background: transparent; /* 设置父元素的背景色 */
      border-bottom: 1px solid #ddd; /* 父元素底部边框线 */
      padding-left: 0;
      margin: 0;
      list-style: none;
      position: relative;
      margin-top: 10px;
      flex-wrap: wrap;
    }
    
    .tag {
      font-size: 14px;
      color: #333; /* 标签文字颜色 */
      background: #f0f0f0; /* 未选中的标签背景色 */
      padding: 8px 16px; /* 根据实际需要调整内间距 */
      cursor: pointer;
      transition: background-color 0.3s ease; /* 平滑背景色变化 */
      width: 60px;
      height: 20px;
      position: relative;
      bottom: -6px;
      border-bottom: 1px solid #ddd;
      margin-top: 3px;
    }
    
    /* 未选中的标签样式 */
    .tag:not(.active) {
      transform: scale(1); /* 未选中的标签稍微缩小 */
      border: 1px solid transparent; /* 防止布局抖动 */
    }
    
    /* 选中的标签样式 */
    .tag.active {
      position: relative;
      background: #fff; /* 选中的标签背景色 */
      border-top: 1px solid #ddd; /* 选中标签上边框 */
      border-left: 1px solid #ddd; /* 选中标签左边框 */
      border-right: 1px solid #ddd; /* 选中标签右边框 */
      border-bottom: none; /* 移除选中标签底部边框 */
      transform: scale(1); /* 恢复标签正常大小 */
      z-index: 1; /* 确保边框不被其他元素覆盖 */
      bottom: -1px;
      height: 25px;
    }
    
    .topbox {
        padding: 20px;
        margin: 10px;
        border-radius: 15px;
    }
    
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .select-team {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .select-team-item {
        font-weight: bold;
        cursor: pointer;
        transition: 0.3s ease;
        border-radius: 15px;
    }
    
    .add-team {
        width: 160px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #fff;
        border-radius: 15px;
    }
    
    .home-team {
        border: 5px solid #f0071e;
        background-color: #f08284;
        border-radius: 15px;
    }
    
    .home-team:hover {
        border: 5px solid #f0071e;
        background-color: #f04e51;
    }
    
    .guest-team {
        border: 5px solid #0f74f0;
        background-color: #5d9ff0;
        border-radius: 15px;
    }
    
    .guest-team:hover {
        border: 5px solid #0f74f0;
        background-color: #5088f0;
    }
    
    .vs {
        font-size: 60px;
        font-weight: bold;
        margin: 0px 40px;
    }
    
    .select-start-time {
        font-size: 16px;
        height: 60px;
        line-height: 60px;
        width: 350px;
        margin: 0 auto;
        margin-bottom: 50px;
        border-radius: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        color: #666;
    }
    
    .newGame {
        width: 500px;
        margin: 50px 0px;
        font-size: 20px;
    }
    .select-start-time {
        :deep(.el-input__inner) {
            border: 0px;
            font-size: 16px;
        }
    }
    
    .team-logo-box {
        display: flex;
        padding-left: 0px;
        position: relative;
    }
    
    .team-box {
        display: flex;
        width: 200px;
        flex-direction: column;
    }
    
    .team-vs {
        width: 50px;
        font-weight: bold;
        font-size: 20px;
        line-height: 60px;
        padding: 0px 10px;
    }
    
    .font-blue {
        color: #009dff;
        font-size: 34px
    }
    
    .font-red {
        color: #ff0004;
        font-size: 34px
    }

    
    .team-info-name {
        font-size: 14px;
        font-weight: bold;

    }
    
    .team-info-score {
        text-align: left;
        font-size: 13px;
    }
    
    
    .select-team-box {
        display: flex;
        flex-wrap: wrap;
        height: 500px;
        overflow-y: scroll;
        align-content: flex-start;
       
    }
    
    .team-item {
        border: 1px solid #ccc;
        padding: 15px;
        width: calc(500px / 2.2);
        margin-right: 15px;
        overflow: hidden;
        height: 60px;
        margin-top: 20px;
    }
    
    .team-item:hover {
        border: 1px solid #00c8e7;
        cursor: pointer;
    }
    
    .group-box {
        position: absolute;
        width: 0px;
        height: 0px;
        color: #fff;
        font-weight: bold;
        font-size: 40px;
        opacity: .6;
        right: -60px;
        bottom: -35px;
        z-index: 1;
        /* text-align: center; */
        line-height: 60px;
        border-left: 90px solid transparent;
        border-top: 90px solid transparent; 
        border-bottom: 90px solid #00c8e7;
        border-right: 90px solid #00c8e7;
    }
    
    .home-check,.guest-check {
        position: absolute;
        height: 20px;
        line-height: 20px;
        border: 1px solid #000;
        left: -10px;
        top: -10px;
        padding: 0px 5px;
        border-radius: 20px;
        color: #fff;
    }
    
    .home-check {
        border-color: #f0071e;
        background-color: #f08284;
        
    }
    
    .guest-check {
        border-color: #0f74f0;
        background-color: #5d9ff0;
        
    }
    
    .choose-team {
        border-color: #0f74f0;
    }
    
    .team-select-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 60px;
        margin-top: 40px;
        color: #000
    }
    
    .team-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
    }
    
    .main-box-right-box {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    .main-box-right-item {
        width: calc(100% / 3 - 40px);
        height: 150px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 10px;
        position: relative;
    }
    
    .main-box-time {
        color: #aaaaaa;
        margin-bottom: 10px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;
    }
    
    .main-box-singletime {
        color: #aaaaaa;
        font-size: 12px;
    }
    

    
</style>