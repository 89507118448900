import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import TopNav from './components/TopNav.vue'
import previewPage from './components/previewPage.vue'
import MyCropper from './components/MyCropper.vue'
import MyCropperMobile from './components/MyCropperMobile.vue'
import * as echarts from 'echarts';
import api from './assets/js/api.js';
import VueElementUISkeleton from 'vue-elementui-skeleton';
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import VueTour from 'vue-tour'
import Print from 'vue-print-nb'
import VueClipBoard from 'vue-clipboard2'

require('vue-tour/dist/vue-tour.css')








Vue.component('TopNav',TopNav);
Vue.component('MyCropper',MyCropper);
Vue.component('MyCropperMobile',MyCropperMobile);
Vue.component('previewPage',previewPage);
Vue.use(ElementUI);
Vue.use(VueElementUISkeleton);
Vue.use(VueDPlayer);
Vue.use(VueTour);
Vue.use(Vuex);
Vue.use(Print);
Vue.use(VueClipBoard);
Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;
Vue.config.productionTip = false

Vue.directive('click-preview', {
  bind: function (el, binding, vnode) {
    const clickHandler = () => {
      vnode.context.$emit('preview-image', binding.value);
    };
    // 保存引用，以便可以正确移除
    el.__clickHandler__ = clickHandler;
    el.addEventListener('click', clickHandler);
  },
  unbind: function(el) {
    // 使用保存的引用来移除事件监听器
    el.removeEventListener('click', el.__clickHandler__);
    delete el.__clickHandler__; // 清理引用
  }
});

Vue.mixin({
  created() {
    // 在每个组件实例创建时，设置全局标题
    document.title = '斗战_让参与更有价值';
  },
});

const store = new Vuex.Store({
  state: {
    certificate: {
      size: 'A4',
      background: 'default.jpg'
    },
    photos: [],
    texts: []
  },
  mutations: {
    updateCertificate(state, payload) {
      state.certificate = { ...state.certificate, ...payload };
    },
    addPhoto(state, photo) {
      state.photos.push(photo);
    },
    updatePhoto(state, { index, photo }) {
      Vue.set(state.photos, index, photo);
    },
    addText(state, text) {
      state.texts.push(text);
    },
    updateText(state, { index, text }) {
      Vue.set(state.texts, index, text);
    }
  }
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
