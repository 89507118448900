<template>
    <div class="content">
        <div class="main-box">
            <div class="main-box-left main-box-item">
                <div class="topnav" style="display: flex;justify-content: left;align-items: center;">
                    <i class="el-icon-arrow-left" @click="backHome()" v-if="type == 'edit'"></i>&emsp;
                    <span>{{title}}</span>
                </div>
				<div class="left-main">
					
					<div class="mobile_box">
						<div class="mobile_box-item">
							<div>
								<div style="width: 100%;text-align: center;">转发预览</div>
								<div class="mobile">
									<div class="mobile-head"></div>
									<div class="mobile-btn"></div>
									<div class="mobile-main-box">
										<div class="mobile-main-banner">
											<img style="width: 100%;position: relative;top: -20px;border-radius: 30px 30px 0px 0px;" src="@/assets/images/basketball.jpeg" alt="" />
											<div class="mobile-main-banner-head">
												<span class="mobile-main-banner-name">用户姓名</span>
												<el-avatar class="mobile-main-banner-logo" shape="square" src=""></el-avatar>
											</div>
											<div class="circle_main">
												<div class="circle_left">
													<label>今天</label>
												</div>
												<div class="circle_right">
													<div class="takephoto_icon">
														<i class="el-icon-camera-solid"></i>
													</div>
												</div>
											</div>
											<div class="circle_main">
												<div class="circle_left">
													<label>昨天</label>
												</div>
												<div class="circle_right">
													<div class="share_box">
														<span class="share_box_title">{{form.name || '输入标题查看预览内容,输入标题查看预览内容,输入标题查看预览内容'}}</span>
														<div class="share_box_info">
															<span class="share_box_info_time">时间：{{form.date1}}</span>
															<span class="share_box_info_site">地点：{{form.city}}</span>
															<el-image
																  class="share_box_info_img"
																  :src="form.shareImg"
																  fit="cover">
																	<div slot="error" class="image-slot">
																		<i class="el-icon-picture-outline"></i>
																	</div>
															</el-image>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="main-box-editor" style="margin-top: 20px;width: 80%;">
								<el-form :rules="rules" label-width="100px" class="demo-ruleForm">
									<el-form-item label="图文标题">
									  <el-input v-model="form.name" placeholder="请输入图文标题"></el-input>
									</el-form-item>
									
									<el-form-item label="图文内容">
									  <el-input type="textarea" v-model="form.desc" placeholder="请输入图文介绍"></el-input>
									</el-form-item>
									<el-form-item label="转发预览图">
										<el-upload
											action="''"
											class="upload3"
											drag
											:auto-upload="false"
											:show-file-list="false"
											:on-change="handleChangeUploadShare">
											<img v-if="form.shareImg" :src="form.shareImg" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
											<template #tip>
												<div class="el-upload__tip">请上传jpg/png格式图片，100*100像素，2M以下图片<br>
												如果无法上传图片，请看看文件扩展名是否jpg或png
												</div>
											</template>
										</el-upload>
									</el-form-item>
								</el-form>
							</div>
						</div>
						<div class="mobile_box-item">
							<div>
								<div style="width: 100%;text-align: center;">内容预览</div>
								<div class="mobile">
									<div class="mobile-head"></div>
									<div class="mobile-btn"></div>
									<div class="mobile-main-box">
										<div class="mobile-main-banner">
											<el-image
												  :src="form.Img"
												  style="height: 150px;line-height: 150px;font-size: 60px;width: 100%;position: relative;top: -20px;border-radius: 30px 30px 0px 0px;"
												  fit="cover">
													<div slot="error" class="image-slot">
														<i class="el-icon-picture-outline"></i>
													</div>
											</el-image>
											
										</div>
										<div style="padding: 10px;text-align: left;">
											<span class="inner_title" style="font-size: 16px;font-weight: bold;">{{form.name || "输入标题查看效果,输入标题查看效果,输入标题查看效果"}}</span>
											<div class="inner_btn" style="display: flex;justify-content: space-between;margin-top: 20px;">
												<span style="font-size: 14px;"><span style="color: orange;">10000</span>次照片浏览</span>
												<span style="width: 40px;height: 20px;border: 1px solid #ccc;font-size: 14px;text-align: center;line-height: 20px;border-radius: 5px;">分享</span>
											</div>
											<div class="inner_info" style="font-size: 14px;color: #666;margin-top: 10px;">
												<div style="margin-bottom: 5px;">
													<i class="el-icon-time"></i>
													{{form.date1 || "选择时间"}}
												</div>
												<div>
													<i class="el-icon-location-outline"></i>
													{{form.city || "选择地点"}}
												</div>
											</div>
										</div>
										<div style="text-align: left;padding: 10px;">
											<span style="font-size: 14px;margin-right: 10px;font-weight: bold;">照片直播</span>
											<span style="font-size: 13px;">热门</span>
										</div>
										<div style="text-align: left;padding: 10px;">
											<div style="display: flex;flex-wrap: wrap;">
												<el-image
													class="inner-imgs"
													style="margin-right: 10px;"
													  src="1"
													  fit="cover">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
												</el-image>
												<el-image
												class="inner-imgs"
													  fit="cover">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
												</el-image>
												<el-image
												class="inner-imgs"
												style="margin-right: 10px;"
													  fit="cover">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
												</el-image>
												<el-image
												class="inner-imgs"
													  fit="cover">
														<div slot="error" class="image-slot">
															<i class="el-icon-picture-outline"></i>
														</div>
												</el-image>
											</div>
										</div>
									</div>
									
								</div>
							</div>
							<div class="main-box-editor" style="margin-top: 20px;width: 100%;">
								<el-form :rules="rules" label-width="100px" class="demo-ruleForm">
									<el-form-item label="活动时间">
									  <el-col :span="11">
										<el-date-picker type="date" placeholder="选择日期" value-format="yyyy.M.d" v-model="form.date1" style="width: 100%;"></el-date-picker>
									  </el-col>
									</el-form-item>
									<el-form-item label="活动地点">
									  <el-col :span="11">
										<el-cascader filterable="true" :options="cityData" @change="selectCity"></el-cascader>
									  </el-col>
									</el-form-item>
									<el-form-item label="开场封面图">
										<el-upload
											action="''"
											class="upload1"
											drag
											:auto-upload="false"
											:show-file-list="false"
											:on-change="handleChangeUploadStart">
											<img v-if="form.startImg" :src="form.startImg" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
											<template #tip>
												<div class="el-upload__tip">请上传jpg/png格式图片，300*600像素，2M以下图片<br>
												如果无法上传图片，请看看文件扩展名是否jpg或png
												</div>
											</template>
										</el-upload>
									</el-form-item>
									
									<el-form-item label="内页横图">
										<el-upload
										action="''"
										class="upload2"
										drag
										:auto-upload="false"
										:show-file-list="false"
										:on-change="handleChangeUploadImg">
											<img v-if="form.Img" :src="form.Img" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
											<template #tip>
												<div class="el-upload__tip">请上传jpg/png格式图片，750*422像素，2M以下图片<br>
												如果无法上传图片，请看看文件扩展名是否jpg或png
												</div>
											</template>
										</el-upload>
									</el-form-item>
									
									<el-form-item>
										<el-button type="primary" v-if="type == 'add'" @click="add()">立即发布</el-button>
										<el-button type="primary" v-else @click="onEdit()">立即修改</el-button>
										<el-button @click="reset()">重置</el-button>
									</el-form-item>
								</el-form>
							</div>
						</div>
					</div>

				</div>
            </div>
            <div class="main-box-right main-box-item">
                <div class="topnav" style="display: flex;justify-content: space-between;">
                    <span>图文列表</span>
                </div>
                
                <el-table
                  :data="teamData"
                  style="width: 100%;"
                  border
                  >
                  <el-table-column
                    prop="id"
                    label="序号"
                    align="center"
                    width="50"
                    type="index"
                    >
                  </el-table-column>
                  <el-table-column
				    prop="title"
                    label="图文直播标题"
                    align="center"
                    width=""
                    >
                  </el-table-column>
                  <el-table-column
				    prop="img_num"
                    label="图片数量"
                    align="center"
                    width="80"
                    >
					<template slot-scope="scope">
						<a href="javascript:;" style="color: #009fe2;" @click="showImgList(scope.row.id)">{{scope.row.img_num}}</a>
					</template>
                  </el-table-column>
                  <el-table-column
                    label="视频数量"
                    align="center"
                    width="80"
					prop="video_num"
                    >
					<template slot-scope="scope">
						<a href="javascript:;" style="color: #009fe2;" @click="showVideoList(scope.row.id)">{{scope.row.video_num}}</a>
					</template>
                  </el-table-column>
                  <el-table-column
                    prop="tools"
                    label="操作"
                    align="center"
                    fixed="right"
                    width="350px"
                    >
                    <template slot-scope="scope">
                        <div class="btn-area">
							<el-button class="btn-area-btn" size="mini">生成二维码</el-button>
                            <el-button class="btn-area-btn" size="mini" @click="edit(scope.row.id)">编辑</el-button>
                            <el-button class="btn-area-btn" size="mini" @click="uploadDialogVisible = true" type="warning">上传素材</el-button>
                            <el-button class="btn-area-btn" size="mini" @click="delActive(scope.row.id)" type="danger">删除</el-button>
                        </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="footer-page">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="total"
                      :page-size="limit"
                      @current-change="changepage"
                      :hide-on-single-page="true"
                      >
                    </el-pagination>
                </div>
            </div>
			
			<el-dialog
			  title="提示"
			  :visible.sync="uploadDialogVisible"
			  width="30%">
			  <div style="display: flex;align-items: center;justify-content: center;">
				  <el-upload
					class="upload-demo"
					drag
					action=""
					:on-change="handleChangeUpload"
					:auto-upload="false"
					multiple>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				  </el-upload>
			  </div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
			    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			  </span>
			</el-dialog>
         </div>
        <!-- vueCropper 剪裁图片实现-->
        <my-cropper :showDialog="cropperVisible" :image="cropperImage" @update-logo="handleLogoUpdate" @cancelDialog="closeDialog" :width="cropperWidth" :height="cropperHeight"></my-cropper>
    </div>
</template>

<script>
    export default {
        data() {
          return {
            title: "创建图文直播",
            mode: 'default',
            rules: {
              name: [
                { required: false, message: '', trigger: 'blur' },
              ],
            },
            form: {
                id: 0,
                desc: "",
                name: "",
                data1: "",
                startImg: "",
                Img: "",
                city: "",
				shareImg: ""
            },
            isHistory: false,
            type: "add",
            editId: 0,
            isPreview: false,
            dialogVisible: false,
			uploadDialogVisible: false,
            cropperImage: "",
            previewImg: '', // 预览图片地址
            teamData: [
				{
					id: 1,
					title: "第XXX届XXX比赛图文直播记录",
					info: "实在是精彩无比啊，实在是精彩无比啊，实在是精彩无比啊，实在是精彩无比啊实在是精彩无比啊",
					img_num: 50,
					video_num: 24
				},
				{
					id: 2,
					title: "第XXX届XXX比赛图文直播记录",
					info: "实在是精彩无比啊，实在是精彩无比啊，实在是精彩无比啊，实在是精彩无比啊实在是精彩无比啊",
					img_num: 50,
					video_num: 24
				}
			],
            usedlogo: [
                {
                    id: 1, url: "http://www.douzhan.club/resources/teamlogo/431e6e88-f35f-4b63-8558-4a46671cdf4d.png"
                }
            ],
            defaultlogo: require('@/assets/images/defaultlogo.jpg'),
            pageIndex: 1,
            limit: 5,
            liveid: "",
            oid: "",
            teamname: "",
            total: 0,
            cameraList: [],
			cityData: null,
			cropperVisible: false,
			cropperWidth: 0,
			cropperHeight: 0,
			cropperMode: 'start'
          }
        },
        created() {
            let query = this.$route.query
            if (query.id) {
                this.type = "edit"
                this.title = "编辑活动"
                this.editId = query.id
                this.$api.get(`api/Match/GetActivity/${query.id}`)
                .then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    // let result = response.data.data
                })
                .catch(error => {
                  console.error("There was an error!", error);
                });
                
            }
            
            if (this.liveid == "") {
                this.$api.post('api/Main/GetGame').then(response => {
                    if (response.data.code == 0) {
                        this.$message({
                          type: 'error',
                          message: response.data.data
                        });
                        this.$router.push({ path: '/login'});
                        return;
                    }
                    this.liveid = response.data.data.id
                    this.oid = response.data.data.oid
                    this.fetchTeamData()
                }).catch(error => {
                    console.error("There was an error!", error);
                })
            } else {
                this.fetchTeamData()
            }
			this.loadCityData();
        },
        watch: {  
            '$route.query.id'(newVal) {  
              // 当路由的查询参数id发生变化时，执行这里的代码  
              if (newVal) {  
                // 如果新值存在，则进入编辑模式  
                this.type = "edit";  
                this.title = "编辑活动";  
                this.editId = newVal;  
                this.editBody = this.teamData.find(item=>item.id === newVal) 
              } else {  
                // 如果新值为空，则可能进入添加模式或其他默认模式  
                this.type = "add"; // 或设置为默认模式  
                this.title = "创建活动"; // 或设置为默认标题  
                this.editId = null;  

                this.reset()
              }  
            },  
          },
      
        methods: {
            onCreated(editor) {
                this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            },
            getCameraList() {
                let formData = new FormData();
                formData.append('oid', this.oid);
                this.$api.post('app/user/organizerseats', formData).then(response => {
                    this.cameraList = response.data.data;
                    this.editBody.seatList = response.data.data;
                })
            },
            getCameraName(id) {
                const item = this.cameraList.find(item=> item.id === id)
                if (item) {
                    return item.name
                } else {
                    return ""
                } 
            },
            fetchTeamData() {
                
                this.$api.get('api/Match/Activitys', {
                    params: {
                      PageIndex: 1,
                    }
                }).then(response => {
                    this.teamData = response.data.rows;
                    // this.total = response.data.total;
                })
            },
            changepage(p) {
                this.pageIndex = p
                this.fetchTeamData()
            },
            delActive(id) {
                this.$confirm('确认删除该活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.teamData = this.teamData.filter(item=> item.id !== id) 
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            add() {
                if (this.editBody.title == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写图文直播标题'
                    });
                    return;
                }
                
                if (this.editBody.introduce == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写图文直播介绍'
                    });
                    return;
                }
                

                this.$confirm('确认创建图文直播?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
						id: this.teamData.length,
						title: this.editBody.title,
						info: this.editBody.introduce,
						img_num: 0,
						video_num: 0
					}
					this.teamData.push(data)
					this.reset()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            },
            edit(id) {
                this.$router.push({ path: '/articlelive', query: { id:  id} });
            },
            backHome() {
                this.$router.push({ path: '/articlelive' });
            },
            onEdit() {
                if (this.editBody.title == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动名称'
                    });
                    return;
                }
                
                if (this.editBody.introduce == "") {
                    this.$message({
                        type: 'warning',
                        message: '请填写活动内容'
                    });
                    return;
                }
                
                this.$confirm('确定要修改吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    }); 
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消操作'
                    });          
                }); 
            },
            reset() {
                this.editBody = {
                    id: 0,
                    introduce: "",
                    title: "",
                    logo: "",
                    yjkssj: "",
                    looktype: "0",
                    seatid: "",
                    seatList: this.cameraList
                }
            },
            handleAvatarSuccess(res, file) {
                this.form.logo = URL.createObjectURL(file.raw);
            },
            handleChangeUpload(file) {
                const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'video/mp4';
                const isLt2M = file.size / 1024 / 1024 < 200;
                if (!isJPG) {
                    this.$message.error('上传文件只能是 JPG/PNG/MP4 格式!');
                    return false
                }
                if (!isLt2M) {
                    this.$message.error('上传文件大小不能超过 200MB!');
                    return false
                }
                // 上传成功后将图片地址赋值给裁剪框显示图片
                this.$nextTick(async () => {
                    // base64方式
					console.log(URL.createObjectURL(file.raw))
                })
            },
			handleChangeUploadStart(file) {
				const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
				    this.$message.error('上传文件只能是 JPG/PNG/MP4 格式!');
				    return false
				}
				if (!isLt2M) {
				    this.$message.error('上传文件大小不能超过 200MB!');
				    return false
				}
				// 上传成功后将图片地址赋值给裁剪框显示图片
				this.$nextTick(async () => {
					this.cropperImage = URL.createObjectURL(file.raw)
					this.cropperWidth = 300
					this.cropperHeight = 600
					this.cropperVisible = true
					this.cropperMode = 'start'
				})
			},
			handleChangeUploadShare(file) {
				const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
				    this.$message.error('上传文件只能是 JPG/PNG/MP4 格式!');
				    return false
				}
				if (!isLt2M) {
				    this.$message.error('上传文件大小不能超过 200MB!');
				    return false
				}
				// 上传成功后将图片地址赋值给裁剪框显示图片
				this.$nextTick(async () => {
					this.cropperImage = URL.createObjectURL(file.raw)
					this.cropperWidth = 100
					this.cropperHeight = 100
					this.cropperVisible = true
					this.cropperMode = 'share'
				})
			},
			handleChangeUploadImg(file) {
				const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
				    this.$message.error('上传文件只能是 JPG/PNG/MP4 格式!');
				    return false
				}
				if (!isLt2M) {
				    this.$message.error('上传文件大小不能超过 200MB!');
				    return false
				}
				// 上传成功后将图片地址赋值给裁剪框显示图片
				this.$nextTick(async () => {
					this.cropperImage = URL.createObjectURL(file.raw)
					this.cropperWidth = 750
					this.cropperHeight = 422
					this.cropperVisible = true
					this.cropperMode = 'Img'
				})
			},
            base64ToBlob(base64Data) {
                // 分割数据
                let parts = base64Data.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
            
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
            
                return new Blob([uInt8Array], {type: contentType});
            },
            handleLogoUpdate(croppedImageUrl) {
				if (this.cropperMode == 'start') {
					this.form.startImg = croppedImageUrl
				} else if(this.cropperMode == 'Img') {
					this.form.Img = croppedImageUrl
				} else {
					this.form.shareImg = croppedImageUrl
				}
                // let blobImage = this.base64ToBlob(croppedImageUrl);
				
                // let formData = new FormData();
                // formData.append('file', blobImage, +new Date+'.jpg');
                // this.$api.post('api/Cms/Articleupload', formData).then(response => {
                //     this.editBody.logo = response.data.data;
                // })
            },
            closeDialog() {
                this.cropperVisible = false
            },
			loadCityData() {
			  fetch('/city.json').then(response => {
				if (!response.ok) {
				  throw new Error('Network response was not ok ' + response.statusText);
				}
				return response.json();
			  })
			  .then(data => {
				this.cityData = data
			  })
			  .catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
			  });
			},
			selectCity(value) {
				let p = this.cityData.find(item=>item.value == value[0]) 
				let c = p.children.find(item=>item.value == value[1]) 
				this.form.city = p.label+'-'+c.label
			}
            
            
        }
      }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang='less' scoped>
    .content {
        padding: 0px;
        background-color: #f0f0f0;
        width: calc(100% - 20px);
        
    }
    .main-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .main-box-item {
        width: 49%;
    }
    
    .main-box-left {
        min-height: calc(100vh - 240px);
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        /* width: calc(65% - 50px); */
        width: 100%;
        margin: 10px;
    }
    
    .main-box-right {
        background-color: #fff;
        border-radius: 15px;
        padding: 20px;
        width: calc(35% - 50px);
        margin: 10px;
		display: none;
    }
    .topnav {
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        height: 40px;
        line-height: 40px;
        padding-bottom: 20px;
    }
    
    .btn-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .btn-area-btn {
        width: 120px;
        margin-bottom: 10px;
    }
    
    .search-input {
        width: 200px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid #ccc;
        text-indent: 1em;
    }
    
    .el-icon-search {
        position: absolute;
        right: 10px;
        top: 60%;
        color: #666;
    }
    
    .create_submit {
        width: 220px;
    }

    .main-box-1 {
        display: flex;
        justify-content: left;
    }
    
    .el-icon-arrow-left {
        margin-right: 0px!important;
    }
    
    .content {
        width: 100%;
        height: 100%;
    }
    
    .form-box {
        width: 380px;
        margin: 20px;
        font-size: 20px;
    }
    
    .el-form-item {
        font-weight: bold;
        text-align: left;
    }
    
	.upload1 /deep/ .el-upload-dragger {
		width: 120px!important;
		height: 240px!important;
		align-items: center;
		line-height: 240px;
	}
	
	.upload2 /deep/ .el-upload-dragger {
		width: 250px!important;
		height: 140px!important;
		align-items: center;
		line-height: 140px;
	}
	
	.upload3 /deep/ .el-upload-dragger {
		width: 100px!important;
		height: 100px!important;
		align-items: center;
		line-height: 100px;
	}
    
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        line-height: 110px;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 30px;
        color: #8c939d;
        /* width: 100px; */
        /* height: 100px; */
        text-align: center;
      }
      .avatar {
        width: 100%;
        height: 100%;
        display: block;
      }
      
      .cropper-content {
          .cropper {
              width: auto;
              height: 350px;
              margin-bottom: 10px;
          }
       }
       
       .historyLogos {
           width: calc(100% - 80px);
           margin: 0 auto;
           font-size: 18px;
           font-weight: bold;
           margin-top: 50px;
       }
       
       .logos {
           display: flex;
           flex-wrap: wrap;
           padding-top: 20px;
       }
       
       .logos img {
           width: 100px;
           height: 100px;
           border: 1px solid #ccc;
           border-radius: 5px;
           margin-right: 20px;
           margin-bottom: 20px;
           cursor: pointer;
       }
       
       .logos img:hover {
           border: 1px solid #13b4ff
       }
       
       .upload-demo {
           width: auto;
           margin-right: 10px;
           float: left;
       }
       
       .footer-page {
           margin-top: 20px;
       }
       
       .notice-title {
           height: 80px;
           line-height: 80px;
           border-bottom: 1px solid #ccc;
           
       }
       
       .notice-title {
           :deep(.el-input__inner) {
               border: 0px;
               font-size: 26px;
           }
       }
       
       .upload-file {
           width: 80px;
           height: 80px;
           border: 1px dashed #ccc;
           text-align: center;
           line-height: 80px;
           font-size: 26px;
       }
       
       .team-logo-box {
            display: flex;
            padding-left: 20px;
        }
        
        .team-info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            line-height: 30px;
            text-align: left;
        }
        
        .team-info-name {
            font-size: 18px;
            font-weight: bold;
        }
        
        .team-info-score {
            text-align: left;
            font-size: 14px;
            line-height: 20px;
        }  
		
		.left-main {
			display: flex;
			justify-content: left;
		}
		
		.mobile {
		    height: 530px;
		    border: 20px solid #dddddd;
		    width: 260px;
		    border-radius: 50px;
		    position: relative;
		    margin: 0 auto;
		    margin-top: 20px;
		    background-color: #fff;
		    /* overflow: hidden; */
		}
		
		
		
		.mobile-head {
		    width: 140px;
		    height: 20px;
		    background-color: #dddddd;
		    margin: 0 auto;
		    border-radius: 0px 0px 30px 30px;
			z-index: 6;
			position: relative;
		}
		
		
		.mobile-btn {
		    width: 10px;
		    height: 80px;
		    border-radius: 0px 10px 10px 0px;
		    background-color: #dddddd;
		    position: absolute;
		    right: -28px;
		    top: 100px;
		}
		
		.mobile-main-box {
		    /* padding: 10px; */
		    height: 530px;
			z-index: 5;
		    /* overflow-y: scroll; */
			overflow: hidden;
			position: absolute;
			top: 0;
			border-radius: 30px;
		}
		
		.mobile-main-box::-webkit-scrollbar {
		    display: none; /* 隐藏滚动条 */
		}
		
		/* 针对Firefox */
		.mobile-main-box {
		    scrollbar-width: none; /* Firefox */
		}
		
		.mobile_box {
			margin-right: 40px;
			display: flex;
			width: 100%;
		}
		
		.mobile-main-banner-head {
			position: absolute;
			right: 15px;
			top: 90px;
			display: flex;
		}
		
		.mobile-main-banner-name {
			font-size: 14px;
			font-weight: 600;
			color: #fff;
			margin-right: 10px;
			font-family: 'simhei';
		}
		
		.circle_main {
			width: 100%;
			display: flex;
			margin-bottom: 20px;
			justify-content: left;
			flex-wrap: wrap;
		}
		
		.circle_left {
			width: 70px;
			font-weight: bold;
			font-family: 'simhei';
		}
		
		.takephoto_icon {
			width: 60px;
			height: 60px;
			background-color: #e5e5e5;
			text-align: center;
			line-height: 60px;
		}
		
		.takephoto_icon i {
			font-size: 22px;
			color:#ccc;
		}
		
		.share_box {
			width: 160px;
			height: 80px;
			background-color: #e5e5e5;
			text-align: left;
			padding: 10px;
		}
		
		.share_box_title {
			font-size: 14px;
			text-align: left;
			display: -webkit-box;
			  -webkit-line-clamp: 2; /* 显示两行 */
			  -webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
		}
		
		.share_box_info {
			font-size: 12px;
			color: #a3a3a3;
			display: flex;
			flex-direction: column;
			margin-top: 10px;
			position: relative;
		}
		
		.share_box_info_img {
			width: 40px;
			height: 40px;
			position: absolute;
			right: -5px;
			bottom: -5px;
			text-align: center;
			line-height: 40px;
		}
		
		.share_box_info_img i {
			background-color: #fff;
			width: 100%;
			height: 40px;
			display: block;
			line-height: 40px;
			font-size: 18px
		}
		
		.inner-imgs {
			border: 1px solid #ccc;
			width: calc(50% - 10px);
			height: 80px;
			margin-bottom: 10px;
			text-align: center;
			line-height: 80px;
			font-size: 40px;
			color: #ccc;
			
		}
		
		.mobile_box-item {
			width: 50%;
			margin-right: 30px;
			display: flex;
		}
		
		
</style>